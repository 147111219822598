import React from 'react'

const Notifications = () => {
  return (
    <div className="notifications_container">
        <div className="notifications_container_title">
            <h1><i className="fa fa-bell" aria-hidden="true"></i>Notifications</h1>
            <hr />
        </div>
    </div>



  )
}

export default Notifications