import {NavLink} from "react-router-dom";
import Notfound from "../../assets/images/notfound.png"

const NotFoundPage=()=>{
    return(
        <section className="notfound">
            
            <img src={Notfound} alt="notfound__image" />
            <p>Oops..! Something went wrong..</p>
            <p>This is a 404 error, which means you've clicked on a bad link or entered an invalid URL.Navigate to <NavLink to="/">Home Page</NavLink></p>
        </section>
    )
};
export default NotFoundPage;