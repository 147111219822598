import React from "react";

const Posts = () => {
  return (
    <div className="settings_container">
      <div className="settings_container_title">
        <h1>
        <i className="fa fa-cog" aria-hidden="true"></i>
settings
        </h1>
        <hr />
      </div>
      
    </div>
  );
};

export default Posts;
