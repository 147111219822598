import React from "react";
import { NavLink } from "react-router-dom";

const Home=()=>{
    return (
        <div className="brand_home">
            
        <h1 className="brand_home_welcome">Welcome to Blogging Portal</h1>Currently Home page is Development Stage Navigate to <NavLink to="/blogs">Blogs Page</NavLink>.
        {/* <!-- ======= Hero Section ======= --> */}

  
        </div>
    )
};
export default Home;