import React from "react";
import Modelimage from "../../../../assets/images/card1/modelimage.jpg";
import question from "../../../../assets/images/card1/question.png";
import confusionmetric from "../../../../assets/images/card1/confusionmetric.png";
import accuracy from "../../../../assets/images/card1/accuracy.png";
import precision from "../../../../assets/images/card1/precision.png";
import recal from "../../../../assets/images/card1/recal.png";
import precision_recall from "../../../../assets/images/card1/precision_recall.png";
import precision_score from "../../../../assets/images/card1/precision_score.png";
import logloss from "../../../../assets/images/card1/logloss.png";
import regression from "../../../../assets/images/card1/reg.jpg";
import profileimage from "../../../../assets/images/card1/p.png";
import MAE from "../../../../assets/images/card1/meanabsolute.png";
import MSE from "../../../../assets/images/card1/mse.png";
import RMSE from "../../../../assets/images/card1/rmse.png";
import predval from "../../../../assets/images/card1/pedval.png";
import coefficient from "../../../../assets/images/card1/coeff.png";
import adsquare from "../../../../assets/images/card1/adsquare.png";


const ModelEvaluation = () => {
  return (
    <div>
      
      <div className="blog_container">
        <img
          src={Modelimage}
          alt="failed"
          className="blog_container_modelimage"
        />

        <p>
          Before jump into Evaluation metrics, first let we know what are the
          main steps involved in ML Model building
        </p>
        <ol>
          <li>Data Gathering</li>
          <li>Data Exploration(EDA)</li>
          <li>Feature Engineering</li>
          <li>Data Preprocessing</li>
          <li>Model Building</li>
          <li>Model Evaluation</li>
        </ol>
        <h2>Why Model Evaluation?</h2>
        <p>
          Evaluation helps you understand more about your Model. It tells
          whether your model has memorized or learnt. This is very important if
          your model has only memorized instead of learning, the model performs
          well for known data and making the model inefficient.
          <p>
            Again it depends on Predictive modeling types(Supervised,
            Unsupervised)
          </p>
          <p>
            In this article we will discuss about supervised models evaluations.
          </p>
          <p>
            Supervised ML divided into two, are Regression and Classification as
            below👇
          </p>
        </p>

        <img src={question} alt="" />
        <p>
          <strong>
            Okay right, we know why evaluation is necessary, now let know, do we
            use the same evaluation metrics for both classification and
            regression tasks?
          </strong>
          <p>The answer is NO.</p>
        </p>
        <h2>Classification:</h2>
        <p>
          Classification is about predicting the class labels given input data.
          In binary classification, there are only two possible output
          classes(Yes-No, Spam-Ham, Dog-Cat, 0-1). In multiclass classification,
          more than two possible classes(oranges, apples, or pears, 0,1,2,3...)
          can be present. I’ll focus only on binary classification.
        </p>
        <p>
          There are many ways for measuring classification performance.
          confusion matrix, Accuracy, log-loss, and AUC-ROC are some of the most
          popular metrics. Precision-recall is a widely used metrics for
          classification problems.
        </p>
        <p>
          <p>Confusion Metrics</p>
        </p>
        <img
          src={confusionmetric}
          alt="not found"
          className="confusionmetrics"
        />
        <br />
        <p>
          The element number of true negatives is the number of rows classified
          by the model as ‘Negative’ but are actually ‘Negative’.
        </p>
        <br />
        <p>
          The element number of false negatives is the number of rows classified
          by the model as ‘Positive’ but are actually ‘Negative’.
        </p>
        <br />
        <p>
          With the same reasoning, we can understand what number of true
          positives and the number of false positives mean.
          <br />
          we learned how confusion metrics works, now let's turn our focus to
          the first and most popular evaluation metric used for the
          classification tasks.
        </p>
        <h2>Accuracy</h2>

        <p>
          Accuracy is the fraction of predictions our model got right. Formally,
          accuracy has the following definition:
        </p>
        <p>Accuracy= No. of correct predictions/Total number of predictions</p>
        <p>
          accuracy can also be calculated in terms of positives and negatives as
          follows:
        </p>
        <img src={accuracy} alt="" />
        <p>
          If Accuracy comes out to 0.91, or 91% (91 correct predictions out of
          100 total examples)
        </p>
        <br />
        <h2>Alternatives to Accuracy</h2>
        <ul>
          <li>True positive Rate(TPR)</li>
          <li>False Negative Rate(FNR)</li>
          <li>True Negative Rate(TNR)</li>
          <li>False Positive Rate(FPR)</li>
        </ul>
        <div>Note: Will discuss about about later articles</div>
        <div>
          <h2>Precision</h2>
          <p>Out of all positive prediction, how many are actual positive</p>
          <p>Precision: True Positives/ Total positives Predicted</p>
          <img src={precision} alt="" />
        </div>
        <br />
        <h2>Recall</h2>
        <p>Out of all actual positive, how many predicted positive</p>
        <p>
          <b>Recall:</b> Predicted Positives/Total Actual Positive
        </p>
        <img src={recal} alt="" />
        <h2>Precision/Recall Tradeoff</h2>
        <img src={precision_recall} alt="" />
        <ul>
          <li>High Precision</li>
          <li>Low Recall High Recall</li>
          <li>Low Precision Choice depends</li>
          <li>Upon use case Combined using F1 Score</li>
          <li>F1 Score is Maximum when Precision == Recall</li>
          <li>Lesser F1 Score, Better will be the model</li>
        </ul>
        <img src={precision_score} alt="" />
        {/* <div> */}
        <h2>AUC-ROC Curve</h2>
        <p>
          The name might be a mouthful, but it is just saying that we are
          calculating the “Area Under the Curve” (AUC) of “Receiver
          Characteristic Operator” (ROC). AUC-ROC curve helps us visualize how
          well our machine learning classifier is performing. Although it works
          for only binary classification problems,
        </p>
        <h2>Probability of Predictions</h2>
        <p>
          A machine learning classification model can be used to predict the
          actual class of the data point directly or predict its probability of
          belonging to different classes. The latter gives us more control over
          the result. We can determine our own threshold to interpret the result
          of the classifier. This is sometimes more prudent than just building a
          completely new model!
          <br />
          <br />
          Setting different thresholds for classifying positive class for data
          points will inadvertently change the Sensitivity and Specificity of
          the model. And one of these thresholds will probably give a better
          result than the others, depending on whether we are aiming to lower
          the number of False Negatives or False Positives.
          <br />
          <br />
          The metrics change with the changing threshold values. We can generate
          different confusion matrices and compare the various metrics that we
          discussed in the previous section. But that would not be a prudent
          thing to do. Instead, what we can do is generate a plot between some
          of these metrics so that we can easily visualize which threshold is
          giving us a better result.
        </p>
        <h2>What is the actual AUC-ROC curve?</h2>
        <p>
          The Receiver Operator Characteristic (ROC) curve is an evaluation
          metric for binary classification problems. It is a probability curve
          that plots the TPR against FPR at various threshold values and
          essentially separates the ‘signal’ from the ‘noise’.
          <br />
          <br />
          The Area Under the Curve (AUC) is the measure of the ability of a
          classifier to distinguish between classes and is used as a summary of
          the ROC curve.
          <br />
          <br />
          ** The higher the AUC, the better the performance of the model at
          distinguishing between the positive and negative classes.
        </p>
        <h3>Log Loss</h3>
        <p>
          It is the negative average of the log of corrected predicted
          probabilities for each instance.
        </p>
        <img
          src="https://editor.analyticsvidhya.com/uploads/90149Capture0.PNG"
          alt=""
        />
        <p>
          Accuracy and F1 Score deals with predicted classes directly rather
          than their Probabilities. Where as AUC-ROC and log loss into account
          the probability of classes.
        </p>
        <p>
          We Take the negative average of the log of corrected probabilities in
          order to get a positive values of log loss <br />
          The lower log loss of model, the better its prediction.
        </p>

        <ol>
          <h2>Regression</h2>
          <li>Mean Absolute Error(MAE)</li>
          <li>Mean Squared Error(MSE)</li>
          <li>Root Mean Squared Error(RMSE)</li>
          <li>Root Mean Squared Log Error(RMSLE)</li>
          <li>R-Squared</li>
          <li>Adjusted R-squared</li>
        </ol>
        <p>
          <b>Error:</b>Error basically determines, how far our predicted values
          from actual values.
        </p>
        <img src={regression} alt="" className="regression_img" />

        <p>
          <h3>Blue-Error</h3>The main problem here is to calculate error, we
          have some positive and negative values. That signs actually denotes
          the direction of error
        </p>
        <h2>Mean Absolute Error(MAE)</h2>
        <p>
          So we can remove these signs(direction) by take magnitude into
          consideration.
        </p>
        <img src={MAE} alt="" />
        <p>
          One way of doing this is by taking sum of absolute values and then
          calculating the average.
        </p>
        <img src="" alt="" />
        <h2>Mean Squared Error(MSE)</h2>
        <p>
          It is simply the average of the squared difference between the target
          value and the value predicted by the regression model.
        </p>
        <img src={MSE} alt="" />
        <h2>Root Mean Squared Error(RMSE)</h2>
        <p>
          square root of the averaged squared difference between the target
          value and the value predicted by the model. It is preferred more in
          some cases because the errors are first squared before averaging which
          poses a high penalty on large errors. This implies that RMSE is useful
          when large errors are undesired.
        </p>
        <img src={RMSE} alt="" />
        <h3>Problem:</h3>
        <p>above metrics of regression alone not intutive</p>
        <p>No benchmark model to compare</p>
        <h2>Relative Squared Error</h2>
        <p>
          In order to calculate Relative Squared Error, you take the Mean
          Squared Error (MSE) and divide it by the square of the difference
          between the actual and the mean of the data. In other words, we divide
          the MSE of our model by the MSE of a model which uses the mean as the
          predicted value
        </p>
        <img src={predval} alt="" />
        <p>
          If the value is lesser than or equals to 1, which means our model is
          good as predicting the average.
        </p>
        <p>
          If this value is more than 1, we can say that our model is worse than
          baseline model.
        </p>
        <h2>R Squared</h2>
        <p>
          Coefficient of Determination or R² is another metric used for
          evaluating the performance of a regression model.
        </p>
        <p>
          The metric helps us to compare our current model with a constant
          baseline and tells us how much our model is better.
        </p>
        <img src={coefficient} alt="" />
        <p>
          The constant baseline is chosen by taking the mean of the data and
          drawing a line at the mean. R² is a scale-free score that implies it
          doesn't matter whether the values are too large or too small, the R²
          will always be less than or equal to 1.
        </p>
        <h2>Adjusted R²:</h2>
        <p>
          Adjusted R² depicts the same meaning as R² but is an improvement of
          it. R² suffers from the problem that the scores improve on increasing
          terms even though the model is not improving which may misguide the
          researcher. Adjusted R² is always lower than R² as it adjusts for the
          increasing predictors and only shows improvement if there is a real
          improvement.
        </p>
        <img src={adsquare} alt="" />
        <p>
          I will briefly explain about each individual metrics in upcoming
          articles.
        </p>
        <br />
        <p>
          Thank you for reading all the way down here! I hope this article was
          helpful in your learning journey. I would love to hear in the comments
          about any other evaluation metrics that I have missed. Happy
          Evaluating!.
        </p>
        {/* </div> */}

        {/* for profile card */}
        <div className="card-header_container">
            <h3>Credits:</h3> <div className="vl"></div>
          <div className="card-header">
            <img
              src={profileimage}
              alt="Profile Image"
              className="profile-img"
            />

            <div className="card-body">
              <h2 className="name">Shekar Samurai</h2>
              <p className="job">Data Scientist</p>
              {/* <a href="#" className="mail">yourname@amail.com</a> */}
              
            </div>

            <div className="social-links">
              <a href="https://github.com/ShekarSamurai" className="fab fa-github social-icon"></a>
              <a href="https://twitter.com/Shekarsamurai" className="fab fa-twitter social-icon"></a>
              {/* <a href="#" className="fab fa-youtube social-icon"></a> */}
              <a href="https://www.linkedin.com/in/rajashekar-enagandula/" className="fab fa-linkedin social-icon"></a>
            </div>

            <div className="card-footer">
              <p className="count">
                {/* <span>120k</span> Followers | <span>10k</span> Following */}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* End of profile card */}
    </div>
  );
};
export default ModelEvaluation;
function Profile(){

}