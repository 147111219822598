import { NavLink } from "react-router-dom"
const year= new Date().getFullYear();

const Footer=()=>{
    return (
        // written Z-INDEX for footer component in dashboard&footer.scss
        <footer className="brand_footer">
            <span>&#169; {year} Vignesh.</span>
            <span>Made with &hearts; by VigneshChari</span>


        </footer>
    )
};
export default Footer;