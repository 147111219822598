import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import brandimage from "../../../assets/images/brandimage.png";
const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  return (
    <header className="brand_header">
       <NavLink className={"logo"} to="/">
          <img src={brandimage} alt="Brand Image not found" />
        </NavLink>
      <nav
        className={
          isMobile ? "brand_header_links_mobile" : "brand_header_links"
        }
        onClick={() => setIsMobile(false)}
      >
        {/* <NavLink className={"brand_header_links_item"} to="/">
          <img src={brandimage} alt="Brand Image not found" />
        </NavLink> */}
        <NavLink to="/blogs" className="brand_header_links_items">
          Blogs
        </NavLink>
        <NavLink to="login" className="brand_header_links_items">
          Login
        </NavLink>
        <NavLink to="signup" className="brand_header_links_items">
          Signup
        </NavLink>
        <NavLink to="/contact" className="brand_header_links_items">
          Contact
        </NavLink>

        {/* <div className="brand_links_mobile">
                <div className="brand_links_mobile_container">
                <NavLink to="/" >Home</NavLink>
                <NavLink to="/blogs" >Blogs</NavLink>
               <NavLink to="login" >Login</NavLink>
               <NavLink to="signup">Signup</NavLink>
               <NavLink to="/contact">Contact</NavLink>

                </div>

               </div> */}
      </nav>

      <button className="mobile-menu" onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? (
          <i className="fa fa-times" aria-hidden="true"></i>
        ) : (
          <i className="fa fa-bars" aria-hidden="true"></i>
        )}
      </button>
    </header>
  );
};
export default Header;
