import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import logo from "../../../assets/images/brandimage.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faBlog,faGear,faBell,faBars,faChartLine } from '@fortawesome/free-solid-svg-icons';


function SideMenuBar() {
 
  return (
    <>
    <div className="header">
        <div className="header_side-nav">
            {/* <img src={logo} alt="" className="header_side-nav_logo"/> */}
            <div className="menu_togle">
            <FontAwesomeIcon icon={faBars} className="menu_toogle"/><h3>Menu</h3>
            
            </div>
            
            <NavLink to="/login/user/dashboard" className="header_side-nav_link_items" style={({ isActive }) => 
                      (isActive ? {backgroundColor: '#ebeff7',color:'orange',borderRadius:"20px 0 0 20px"} : {backgroundColor: ''})}><FontAwesomeIcon icon={faChartLine} className="icons"/><span> DashBoard</span></NavLink>
            <NavLink to="/login/user/posts"className="header_side-nav_link_items" style={({ isActive }) => 
                      (isActive ? {backgroundColor: '#ebeff7',color:'orange',borderRadius:"20px 0 0 20px"} : {backgroundColor: ''})} ><FontAwesomeIcon icon={faBlog} className="icons"/><span> Posts</span></NavLink>
            <NavLink to="/login/user/notifications"className="header_side-nav_link_items" style={({ isActive }) => 
                      (isActive ? {backgroundColor: '#ebeff7',color:'orange',borderRadius:"20px 0 0 20px"} : {backgroundColor: ''})}><FontAwesomeIcon icon={faBell} className="icons"/><span> Notifications</span></NavLink>
            <NavLink to="/login/user/settings"className="header_side-nav_link_items" style={({ isActive }) => 
                      (isActive ? {backgroundColor: '#ebeff7',color:'orange',borderRadius:"20px 0 0 20px"} : {backgroundColor: ''})}><FontAwesomeIcon icon={faGear} className="icons"/><span> Settings</span></NavLink>
            


            
        </div>
        <NavLink to="" className="mobile_header_side-nav_link_items"><FontAwesomeIcon icon={faChartLine} className="mobile_header_side-nav_link_items_icons"/></NavLink>
            <NavLink to=""className="mobile_header_side-nav_link_items"><FontAwesomeIcon icon={faBlog} className="icons"/></NavLink>
            <NavLink to=""className="mobile_header_side-nav_link_items"><FontAwesomeIcon icon={faBell} className="icons"/></NavLink>
            <NavLink to=""className="mobile_header_side-nav_link_items"><FontAwesomeIcon icon={faGear} className="icons"/></NavLink>
        
    </div>
    <Outlet/>
    </>
  );
}

export default SideMenuBar;
