import React from "react";
import { scryRenderedDOMComponentsWithTag } from "react-dom/test-utils";
import { NavLink } from "react-router-dom";

const Login=()=>{
    return (
        
        <div className="login">
  <div className="heading">
    <h2>Sign in</h2>
    <form action="#" onSubmit={Login_Click}>

      <div className="input-group input-group-lg">
        <span className="input-group-addon"><i className="fa fa-user"></i></span>
        <input type="text" className="form-control" placeholder="Username or email" required/>
          </div>
          

        <div className="input-group input-group-lg">
          <span className="input-group-addon"><i className="fa fa-lock"></i></span>
          <input type="password" className="form-control" placeholder="Password" required/>
          
        </div>
        <p><NavLink to="/login/reset" >Reset Password?</NavLink></p>
        <p><NavLink to="/login/user" >Login Dashboard</NavLink></p>

        <button type="submit" className="float">Login</button>
       </form>
 		</div>
 </div>

        
    )
};
export default Login;

function Login_Click(){
alert("Error Occurred due to Some technical issues facing,..Sorry for the Inconvinience caused.")
}