import React from 'react';
import { NavLink } from 'react-router-dom';
import Card1 from "../../../assets/images/aiss.png";
 import Card2 from "../../../assets/images/card2/ttl.jpg"
 import Card3 from "../../../assets/images/card3/cutting.jpg";
const Blogs= ()=> {
  return (
    <div>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto:wght@300&display=swap" rel="stylesheet"/>

<div className="container">
	<div className="card">
		<figure className="card__thumb">
			<img src={Card1} alt="Picture by Kyle Cottrell" className="card__image"/>
			<figcaption className="card__caption">
				<h2 className="card__title">Evaluation Metrics</h2>
				<p className="card__snippet">This is very important if your model has only memorized instead of learning, the model performs well for known data and making the model inefficient.</p>
				{/* <a href="" className="card__button">Read more</a> */}
                <NavLink to="/blogs/modelevaluation" className="card__button">Read more</NavLink>
			</figcaption>
		</figure>
	</div>

	<div className="card">
		<figure className="card__thumb">
			<img src={Card2} alt="Picture by Nathan Dumlao" className="card__image"/>
			<figcaption className="card__caption">
				<h2 className="card__title">Discretization</h2>
				<p className="card__snippet">Data discretization refers to converting a huge number of data values into smaller ones so that the evaluation and management of data.</p>
				{/* <a href="" className="card__button">Read more</a> */}
				<NavLink to="/blogs/datadecretization" className="card__button">Read more</NavLink>
			</figcaption>
		</figure>
	</div>

	<div className="card">
		<figure className="card__thumb">
			<img src={Card3} alt="Picture by Daniel Lincoln" className="card__image"/>
			<figcaption className="card__caption">
				<h2 className="card__title">Why You Should Bring Your Dog To Work</h2>
				<p className="card__snippet">On Friday, offices around the country celebrated the 15th annual Take Your Dog to Work Day. Though the event's primary goal is to raise awareness for pet adoption, the unanticipated impact may be a slightly more relaxing work environment for any office choosing to participate.</p>
				<a href="" className="card__button">Read more</a>
			</figcaption>
		</figure>
	</div>
</div>

{/* <p className="disclaimer">All pictures were taken from <a className="disclaimer__link" href="https://unsplash.com/" target="_blank">Unsplash.</a></p> */}
    </div>
  )
};export default Blogs;