import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Router from "./router/";
function App() {
  const registerServiceWorker = async () => {
    "serviceWorker" in navigator
      ? window.addEventListener("load", function () {
          navigator.serviceWorker
            .register("/service-worker.js")
            .then(
              (e) => {
                console.log("Worker registration successful", e.scope);
              },

              (e) => {
                console.log("Worker registration failed", e);
              }
            )
            .catch((e) => {
              console.log(e);
            });
        })
      : console.log("Service Worker is not supported by browser.");
  };
  useEffect(() => {
    registerServiceWorker();
  }, []);
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
 <Router></Router>

  );
}

export default App;
