import React, { useState } from "react";

type state = {
  Name: string;
  Email: string;
  Mobile: string;
};
const Contact = () => {
  const [msg,setMsg]=useState("");
  const [state, setState] = useState<state>({
    Name: "",
    Email: "",
    Mobile: "",
  });
  const HandleChange = (e: React.ChangeEvent<any>) => {
    const name = e.target.name;
    const value = e.target.value;
  
    //#region  providing message for user after submit commented below line

   //setMsg("Dear "+state.Name +" we will get back to you shortly"  );
   //#endregion
    //object destructuring
    
    const newState = { ...state, [name]: value };
    setState(newState);
    
  };
  const textChangeHandler = (e: React.ChangeEvent<any>) => {
    
    e.preventDefault();
    setMsg("Dear "+state.Name +" we will get back to you shortly"  );
    e.target.reset();
    //on submitt printing the input in console
    console.log(state);
  };
  return (
    <div className="form-container">
      <p className="form-container_signup_warning">{msg}</p>
      <div className="form-box">
        <h1>Contact Us</h1>
        <hr />

        <form onSubmit={textChangeHandler}>
          <div className="input-group">
            <div className="input-fields">
              <input
                type="text"
                placeholder="Name"
                name="Name"
                required
                onChange={HandleChange}
              />
            </div>
            <div className="input-fields">
              <input
                type="text"
                placeholder="Email"
                name="Email"
                required
                onChange={HandleChange}
              />
            </div>
            <div className="input-fields">
              <input
                type="text"
                placeholder="Mobile"
                name="Mobile"
                required
                onChange={HandleChange}
              />
            </div>
          </div>
          <div className="btn-group">
            <button type="submit">Request</button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Contact;
