import construction from "../../assets/images/construct.png"
import { NavLink } from "react-router-dom"
const Construction=()=>{
    return (
        <div className="underconstruction">
            <img src={construction}alt="" className="underconstruction_img"/>
            <p>Currently this Page is under Construction.</p>
            <p>We will comeback Soon.. <NavLink to="/">Home Page</NavLink></p>
        </div>
    )
};
export default Construction;