import React from "react";
import bgimage from "../../../../assets/images/posts1.png";
const Posts = () => {
  
  return (
    <div className="posts_container">
      
      <div className="posts_container_title">
        <h1>
          <i className="fa fa-book" aria-hidden="true"></i>Posts
        </h1>
        <hr />
      </div>
      <div className="posts_container_baground">
      {/* <img src={bgimage} alt="" /> */}
      
      <div className="posts_container_baground_box">
        <h1>Colum 1</h1>
        <h2>Title</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
      </div>
      <div className="posts_container_baground_box">
        <h1>Colum 1</h1>
        <h2>Title</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
      </div>
      <div className="posts_container_baground_box">
        <h1>Colum 1</h1>
        <h2>Title</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
      </div>
      </div>
        
     
    </div>
  );
};

export default Posts;
