import profileimage from "../../../assets/images/card2/p.png";
const ProfilePublisher=(props:any)=>{
    return (
        <div className="card-header_container">
            <h3>Credits:</h3> <div className="vl"></div>
          <div className="card-header">
            <img
            //   src={profileimage}
              src={props.image}
              alt="Profile Image"
              className="profile-img"
            />

            <div className="card-body">
              <h2 className="name">{props.name}</h2>
              <p className="job">{props.role}</p>
              {/* <a href="#" className="mail">yourname@amail.com</a> */}
              
            </div>

            <div className="social-link">
              <a href="https://github.com/ShekarSamurai" className="fab fa-github social-icon"></a>
              <a href="https://twitter.com/Shekarsamurai" className="fab fa-twitter social-icon"></a>
              {/* <a href="#" className="fab fa-youtube social-icon"></a> */}
              <a href="https://www.linkedin.com/in/rajashekar-enagandula/" className="fab fa-linkedin social-icon"></a>
            </div>

            <div className="card-footer">
              <p className="count">
                {/* <span>120k</span> Followers | <span>10k</span> Following */}
              </p>
            </div>
          </div>
        </div>
    )
};
export default ProfilePublisher;