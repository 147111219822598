import NotFoundPage from "../../../shared/notfound";

export const Dashboard = () => {
  return (
    <div className="dashboard_container">
      <div className="dashboard_container_title">
        <h1>
          <i className="fa fa-line-chart" aria-hidden="true"></i>Dashboard
          <hr />
        </h1>
      </div>
      <NotFoundPage />
    </div>
  );
};
