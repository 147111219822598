import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Signup =()=>{
const [error,setError]=useState("");
const [name,setName]=useState("");
const handleSubmit=(e:React.ChangeEvent<any>)=>{
  e.preventDefault();
  setError("Sorry for the inconvinience caused, due to technical issues failed to create :"+name)
  e.target.reset();
  //()=>setError("Due to technical issues")
}

    return (
       <div>
           
  	<div className="form-container">
      <p className="form-container_signup_warning">{error}</p>
      
      <div className="form-box">
        <h1>SignUp</h1>
        <hr />
        <form  onSubmit={handleSubmit}>
          <div className="input-group">
            
            <div className="input-fields"><i className="fa fa-user-circle" aria-hidden="true"></i>
          <input type="text" placeholder="Username"  required value={name} onChange={(e)=>setName(e.target.value)}/>
            </div>
            <div className="input-fields"><i className="fa fa-envelope" aria-hidden="true"></i>
          <input type="email" placeholder="Email"  required />
            </div>
            <div className="input-fields"><i className="fa fa-key" aria-hidden="true"></i>
          <input type="password" placeholder="Password"  required />
            </div>
            <p> Forgot Password?<NavLink to="/login/reset">Reset</NavLink></p>
           </div>
           <div className="btn-group">
            <button type="submit" >Sign Up</button>
            {/* <button type="submit">Sign In</button> */}

           </div>
        </form>
        

        
        

      </div>
    </div></div>
    )

};
export default Signup;
