import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../components/shared/navigation/footer";
import Header from "../components/shared/navigation/header";
import Blogs from "../pages/public/blogs/blogs";
import ModelEvaluation from "../pages/public/blogs/card1/card1";
import Discretization from "../pages/public/blogs/card2/card2";
import Home from "../pages/public/home/home";
import Login from "../pages/public/login/login";
import Reset from "../pages/public/login/resetpassword";
// import Profile from "../pages/public/profile/profile";
import Construction from "../pages/shared/construction";
import NotFound from "../pages/shared/notfound";
import profileimage from "../assets/images/card2/p.png";
import Signup from "../pages/public/signup/signup";
import Profile from "../components/shared/profilefooter/profile";
import SideMenuBar from "../components/shared/sidemenubar/sidemenubar";
import Contact from "../pages/public/contact/contact";
import { Dashboard } from "../pages/public/login/dashboard/dashboard";
import Posts from "../pages/public/login/dashboard/posts";
import Settings from "../pages/public/login/dashboard/settings";
import Notifications from "../pages/public/login/dashboard/notifications";
const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Header></Header>

      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="home" element={<Home></Home>}></Route>
        <Route path="">
          {/* sublinks under login */}
          <Route path="/blogs" element={<Blogs></Blogs>}></Route>
          <Route
            path="/blogs/modelevaluation"
            element={<ModelEvaluation></ModelEvaluation>}
          ></Route>
          <Route path="/blogs/datadecretization" element={<Discretization />} />
          {/* <Route path="/blogs/discretization" element={<Discretization></Discretization>}></Route> */}
        </Route>
        {/* <Route path="/profile" element={<Profile path={profileimage} name="Shekar Samurai" role="Datascientist"></Profile>}></Route> */}

        <Route path="">
          {/* sublinks under login */}
          <Route path="/login" element={<Login></Login>}></Route>

          <Route path="/login/reset" element={<Reset></Reset>} />

          {/* for dashboard navigation */}
          <Route path="/login/user" element={<SideMenuBar />}>
            <Route path="/login/user/dashboard" element={<Dashboard />} />
            <Route path="/login/user/posts" element={<Posts/>} />
            <Route path="/login/user/settings" element={<Settings/>}/>
            <Route path="/login/user/notifications" element={<Notifications/>}/>
          </Route>
          {/* end of dashboard navigation */}
        </Route>
        {/* <Route path="login/user/dashboard" element={<Dashboard/>}></Route> */}
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="*/construction" element={<Construction />}></Route>
        <Route path="*" element={<NotFound></NotFound>}></Route>
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  );
};
export default Router;
