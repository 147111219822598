import React from "react";
import {Link } from "react-router-dom";
import Sorry from "../../../assets/images/sorry.png";
const Reset=()=>{
    return (
        <div className="brand_pass_reset">
            <img src={Sorry} alt="" className="brand_pass_reset_image"/>
        <p>Sorry for the Inconvinence caused.. </p>
           <p> You dont have chance to reset password right now due to Technical issues Please try again later, Redirect to <Link to="/">Home</Link></p>
        </div>
    )
};
export default Reset;